<template>
  <div>
    <div class="container h-100">
      <div class="row justify-content-center h-100 align-items-center my-5">
        <div class="col-xl-5 col-md-6">
          <div class="auth-form card">
            <div class="card-header">
              <h4 class="card-title">Link a bank account</h4>
            </div>
            <div class="card-body">
              <form action="verify-step-6.html" class="identity-upload" @submit.prevent="addAccount">
                <div class="form-row">
                  <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Routing number </label>
                    <input
                      type="text"
                      class="form-control"
                      maxlength="9"
                      v-model="bank.routing_number"
                    />
                  </div>
                  <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Account number </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="bank.account_number"
                      @blur="bank.c_account_number && validateField()"
                    />
                  </div>
                  <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Confirm account number </label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="bank.c_account_number"
                      @input="validateField"
                      @blur="showError = true"
                    />
                    <p class="text--red" v-if="showError && hasError">Account numbers don't match</p>
                  </div>
                  <div class="form-group col-xl-12">
                    <label class="mr-sm-2">Description</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="bank.description"
                    />
                  </div>
                  <div class="form-group col-xl-12">
                    <img
                      src="@/assets/images/bank-routing-number.jpeg"
                      alt=""
                      class="img-fluid"
                    />
                  </div>

                  <div class="text-center col-12">
                    <router-link to="verify-step-5" class="btn btn-primary mx-2">
                      Back
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-success mx-2"
                      @click.prevent="addAccount"
                      :disabled="isFormValid === false"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data: () => ({
    bank: {
      routing_number: '',
      account_number: '',
      c_account_number: '',
      description: '',
    },
    hasError: false,
    showError: false,
  }),
  computed: {
    isFormValid() {
      if (this.bank.account_number !== this.bank.c_account_number) {
        return false;
      }
      const keys = Object.keys(this.bank);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (this.bank[key].length < 1) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    addAccount() {
      this.$toast.clear();
      api.account.create(this.bank).then((res) => {
        this.$toast.success('Account saved');
        this.bank = {
          routing_number: '',
          account_number: '',
          c_account_number: '',
          description: '',
        };
        this.$router.push({ path: '/settings-account' });
      }).catch((err) => {
        this.$toast.error(err);
      });
    },
    validateField() {
      const isEqual = this.bank.account_number === this.bank.c_account_number;
      if (this.bank.account_number && isEqual) {
        this.hasError = false;
      } else {
        this.hasError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
